import debounce from 'lodash.debounce';
import { OrderedSet, Map } from 'immutable';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import { EmailCampaignsSource } from 'sources';

import MarketingEmailStatisticsActions from 'containers/marketing/statistics/Actions';

class MarketingEmailStatisticsStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();
    this.debouncedSearch = debounce(this.listEmailsStatistics, 500);
    this.bindListeners({
      handlePageSelect: MarketingEmailStatisticsActions.handlePageSelect,
      searchMarketingEmailStatistics:
        MarketingEmailStatisticsActions.searchMarketingEmailStatistics,

      toggleFiltersDrawer: MarketingEmailStatisticsActions.toggleFiltersDrawer,
      handleFilterChange: MarketingEmailStatisticsActions.handleFilterChange,
      applyFilters: MarketingEmailStatisticsActions.applyFilters,
      resetFilters: MarketingEmailStatisticsActions.resetFilters,
      removeFilter: MarketingEmailStatisticsActions.removeFilter,

      listEmailsStatistics:
        MarketingEmailStatisticsActions.listMarketingEmailStatistics,
      listEmailsStatisticsSuccess:
        MarketingEmailStatisticsActions.listMarketingEmailStatisticsSuccess,
      listEmailsStatisticsError:
        MarketingEmailStatisticsActions.listMarketingEmailStatisticsError,
    });
  }

  reset() {
    this.isLoading = false;
    this.filtersDrawerOpen = false;
    this.search = '';
    this.filters = Map();
    this.appliedFilters = Map();
    this.emailsStatisticsIds = OrderedSet();
    this.pagination = Map({
      emailsStatistics: Map({
        page: 1,
        perPage: 15,
        totalCount: 0,
      }),
    });
  }

  getParams() {
    const filters = {
      status: this.appliedFilters.get('status', null),
      created_at_max: this.appliedFilters.get('date_created')?.to || null,
      created_at_min: this.appliedFilters.get('date_created')?.from || null,
      scheduled_at_max: this.appliedFilters.get('sent_at')?.to || null,
      scheduled_at_min: this.appliedFilters.get('sent_at')?.from || null,
    };

    const params = {
      search: this.search,
      page: this.pagination.getIn(['emailsStatistics', 'page']),
      per_page: this.pagination.getIn(['emailsStatistics', 'perPage']),
      ...filters,
    };

    return params;
  }

  listEmailsStatistics() {
    this.isLoading = true;

    EmailCampaignsSource.listStatistics({
      params: this.getParams(),
      success:
        MarketingEmailStatisticsActions.listMarketingEmailStatisticsSuccess,
      error: MarketingEmailStatisticsActions.listMarketingEmailStatisticsError,
    });
  }

  listEmailsStatisticsSuccess({ campaigns, page, perPage, totalCount }) {
    this.pagination = this.pagination
      .setIn(['emailsStatistics', 'page'], page)
      .setIn(['emailsStatistics', 'perPage'], perPage)
      .setIn(['emailsStatistics', 'totalCount'], totalCount);
    this.emailsStatisticsIds = campaigns.map(c => c.id);
    this.isLoading = false;
  }

  listEmailsStatisticsError(...args) {
    this.isLoading = false;
    this.notifyError('error listing email statistics', args);
  }

  handlePageSelect([page]) {
    this.pagination = this.pagination.setIn(['emailsStatistics', 'page'], page);
    this.listEmailsStatistics();
  }

  searchMarketingEmailStatistics(searchText) {
    this.search = searchText;
    this.pagination = this.pagination.setIn(['emailsStatistics', 'page'], 1);
    this.isLoading = true;
    this.debouncedSearch();
  }

  toggleFiltersDrawer() {
    this.filtersDrawerOpen = !this.filtersDrawerOpen;
  }

  handleFilterChange({ field, value }) {
    this.filters = this.filters.set(field, value);
  }

  applyFilters() {
    this.pagination = this.pagination.setIn(['emailsStatistics', 'page'], 1);
    this.appliedFilters = this.filters;
    this.listEmailsStatistics();
  }

  removeFilter(field) {
    this.filters = this.filters.delete(field);
    this.appliedFilters = this.appliedFilters.delete(field);
    this.listEmailsStatistics();
  }

  resetFilters() {
    this.filters = Map();
    this.appliedFilters = Map();
    this.listEmailsStatistics();
  }
}

export default alt.createStore(
  MarketingEmailStatisticsStore,
  'MarketingEmailStatisticsStore'
);
