import MembershipCancellationActions from 'memberships/actions/MembershipCancellationActions.js';
import MembershipSuspensionActions from 'memberships/actions/MembershipSuspensionActions.js';
import RefundModalActions from 'containers/reports/refundModal/Actions';
import EditMembershipExpireActions from 'containers/clientProfile/components/EditMembershipExpireModal/EditMembershipExpireActions.js';
import EditMembershipCancellationActions from 'containers/clientProfile/components/EditMembershipCancellationModal/Actions.js';
import MembershipEmbedActions from 'containers/clientProfile/components/MembershipEmbed/MembershipEmbedActions';
import MembershipChargeDateActions from 'memberships/actions/MembershipChargeDateActions.js';
import AccountCreditActions from 'containers/clientProfile/components/AccountCredits/Actions';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';

import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

import { CREDIT_TYPES } from 'containers/clientProfile/components/AccountCredits/Store';

import ClientProfileActions from './Actions';
import ClientProfileBaseStore from './BaseStore';

class ClientProfileStore extends ClientProfileBaseStore {
  constructor({ actions }) {
    super();

    this.actions = actions;
    this.bindListeners({
      mounted: this.actions.mounted,
      closeDrawer: this.actions.closeDrawer,
      openScheduleDrawer: this.actions.openScheduleDrawer,
      closeScheduleDrawer: [
        this.actions.closeScheduleDrawer,
        RefundModalActions.openModal,
      ],
      toggleOrdersDrawer: this.actions.toggleOrdersDrawer,
      togglePaymentsDrawer: this.actions.togglePaymentsDrawer,
      fetchSuccess: this.actions.fetchSuccess,
      fetchError: this.actions.fetchError,
      listManagedProfiles: this.actions.listManagedProfiles,
      listManagedProfilesSuccess: this.actions.listManagedProfilesSuccess,
      listManagedProfilesError: this.actions.listManagedProfilesError,
      fetchSubscription: [
        this.actions.fetchSubscription,
        EditMembershipExpireActions.updateDateSuccess,
        EditMembershipCancellationActions.updateCancellationDateSuccess,
        MembershipSuspensionActions.suspendMembershipSuccess,
        MembershipSuspensionActions.reactivateMembershipSuccess,
        MembershipCancellationActions.cancelMembershipSuccess,
        MembershipEmbedActions.recycleEmbedCardSuccess,
        MembershipChargeDateActions.handleChargeDateChangeSuccess,
      ],
      fetchSubscriptionSuccess: this.actions.fetchSubscriptionSuccess,
      fetchStatisticSuccess: this.actions.fetchStatisticSuccess,
      fetchStatisticError: this.actions.fetchStatisticError,
      tabSelected: this.actions.tabSelected,
      imageSelected: this.actions.imageSelected,
      clientSaved: this.actions.clientSaved,
      clientUpdateSuccess: this.actions.clientUpdateSuccess,
      clientUpdateError: this.actions.clientUpdateError,
      listOrders: this.actions.listOrders,
      listOrdersSuccess: this.actions.listOrdersSuccess,
      listOrdersError: this.actions.listOrdersError,
      listOrdersPrevNext: this.actions.listOrdersPrevNext,
      listRegistrations: this.actions.listRegistrations,
      listRegistrationsSuccess: this.actions.listRegistrationsSuccess,
      listRegistrationsPrevNext: this.actions.listRegistrationsPrevNext,
      listPastRegistrationsSuccess: this.actions.listPastRegistrationsSuccess,
      listRegistrationsError: this.actions.listRegistrationsError,
      listEventsSuccess: this.actions.listEventsSuccess,
      listSessionsSuccess: this.actions.listSessionsSuccess,
      openOrderDetailsDrawer: this.actions.openOrderDetailsDrawer,
      fetchOrderSuccess: this.actions.fetchOrderSuccess,
      fetchOrderError: this.actions.fetchOrderError,
      openSessionSummaryDrawer: this.actions.openSessionSummaryDrawer,
      closeSessionSummaryDrawer: this.actions.closeSessionSummaryDrawer,
      registrationCancel: this.actions.registrationCancel,
      registrationCancelSuccess: this.actions.registrationCancelSuccess,
      registrationCancelError: this.actions.registrationCancelError,
      setRegistrationToRemove: this.actions.setRegistrationToRemove,
      setCreditOperation: this.actions.setCreditOperation,
      setWaive: this.actions.setWaive,
      openViewBalanceDrawer: this.actions.openViewBalanceDrawer,
      onPaymentSuccess: this.actions.onPaymentSuccess,
      paginationReset: this.actions.paginationReset,
      handleGoBack: this.actions.handleGoBack,
      handleToggleFileUploadDrawer: this.actions.toggleFileUploadDrawer,
      handlePdfUploadChange: this.actions.onPdfFileChange,
      handleDeleteFile: this.actions.onDeleteFile,
      documentSelected: this.actions.documentSelected,
      updateAccountCreditTotal: AccountCreditActions.createCreditSuccess,
      updateSwingsTotal: MembershipEmbedActions.redeemSuccess,
      verifyEmail: this.actions.verifyEmail,
      verifyEmailSuccess: this.actions.verifyEmailSuccess,
      verifyEmailError: this.actions.verifyEmailError,
      resetPassword: this.actions.resetPassword,
      resetPasswordSuccess: this.actions.resetPasswordSuccess,
      resetPasswordError: this.actions.resetPasswordError,
      handleAdditionalEmailsList: this.actions.additionalEmailsList,
      handleAdditionalEmailsListSuccess:
        this.actions.additionalEmailsListSuccess,
      handleAdditionalEmailsListError: this.actions.additionalEmailsListError,
      handleUpdateStore: this.actions.updateStore,
      handlePostEmail: this.actions.postAdditionalEmail,
      handlePostEmailSuccess: this.actions.postAdditionalEmailSuccess,
      handlePostEmailError: this.actions.postAdditionalEmailError,
      handleUpdateEmail: this.actions.updateAdditionalEmail,
      handleUpdateEmailSuccess: this.actions.updateAdditionalEmailSuccess,
      handleUpdateEmailError: this.actions.updateAdditionalEmailError,
      handleDeleteEmail: this.actions.deleteAdditionalEmail,
      handleDeleteEmailSuccess: this.actions.deleteAdditionalEmailSuccess,
      handleDeleteEmailError: this.actions.deleteAdditionalEmailError,
      handleOpenModal: this.actions.openModal,
      handleCloseModal: this.actions.closeModal,
      handleSetAdditionalEmail: this.actions.setAdditionalEmail,
    });
  }

  updateAccountCreditTotal({ credit_type: creditType, amount }) {
    this.totalAccountCredit =
      creditType === CREDIT_TYPES.clear
        ? this.totalAccountCredit - amount
        : this.totalAccountCredit + amount;
  }

  updateSwingsTotal({ swings_redeemed: swingsRedeemed }) {
    const message = new TranslatableMessage({
      id: 'containers.clientProfile.MembershipEmbed.MembershipEmbedEditModal.redeem_success',
      values: { n: swingsRedeemed },
    });

    this.totalCashCredits += swingsRedeemed;

    MessageWindowActions.addMessage.defer(message);
  }
}

export default alt.createStore(ClientProfileStore, 'ClientProfileStore', {
  actions: ClientProfileActions,
});
