import * as React from 'react';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';

import Hand from 'shared/components/icons/Hand.jsx';
import activeTheme from '_uh_theme.jsx';
import { FlexBoxColumnLayout } from 'shared/components/FlexBox.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { isPowerBallersTheme } from 'shared/utils/ThemeUtils';

function ThemeSidebar({ modalOpen, className, intl }) {
  const styles = {
    boldBigText: {
      color: uhColors.green,
      fontSize: '3.4rem',
      fontStyle: 'italic',
      fontWeight: 'bold',
      paddingTop: 50,
      lineHeight: '36px',
    },
    italicBigText: {
      fontSize: '3.4rem',
      color: uhColors.white,
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
    regularText: {
      paddingTop: 12,
      fontSize: '1rem',
      color: uhColors.white,
      fontWeight: '100',
      lineHeight: '14px',
      margin: '0 auto',
      maxWidth: 230,
    },
  };

  if (!activeTheme.mainInstance) {
    styles.regularText.lineHeight = '30px';
  }

  const logo = () => {
    if (!activeTheme.mainInstance) {
      return <div />;
    }

    return (
      <div>
        <Hand
          style={{
            height: 90,
            width: 90,
          }}
        />
      </div>
    );
  };

  const learnMore = () => {
    const val = t('.learn_more', intl, __filenamespace);

    if (!val) {
      return <div />;
    }

    if (isPowerBallersTheme()) {
      return null;
    }

    return (
      <div>
        <Button
          href={activeTheme.siteURL}
          sx={{
            color: 'white',
            height: '36px',
            minWidth: '88px',
            '&:hover': { backgroundColor: 'transparent' },
          }}
        >
          {val}
        </Button>
      </div>
    );
  };

  return (
    <div
      className={className}
      style={{
        maxWidth: modalOpen ? '36rem' : '28rem',
        minWidth: '28rem',
        background: activeTheme.login.desktopSignInColor,
        height: '100%',
        position: modalOpen ? 'initial' : 'fixed',
        width: '100%',
      }}
    >
      <FlexBoxColumnLayout
        childrenStyle={{ margin: 0, textAlign: 'center' }}
        style={{
          height: '100%',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ flex: '0 0 auto' }}>
          {logo()}
          <div style={styles.boldBigText}>
            {t('.header', intl, __filenamespace)}
          </div>
          <div style={styles.italicBigText}>
            {t('.subHeader', intl, __filenamespace)}
          </div>
          <div style={styles.regularText}>
            {t('.mission', intl, __filenamespace)}
          </div>
          <div style={styles.regularText}>
            {t('.mission1', intl, __filenamespace)}
          </div>
          <div style={styles.regularText}>
            {t('.mission2', intl, __filenamespace)}
          </div>
          <div style={styles.regularText}>
            {t('.mission3', intl, __filenamespace)}
          </div>
          {learnMore()}
        </div>
      </FlexBoxColumnLayout>
    </div>
  );
}

export default injectIntl(ThemeSidebar);
