import debounce from 'lodash.debounce';
import moment from 'moment-timezone';
import { OrderedSet, Map } from 'immutable';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import { downloadFile } from 'shared/utils/SharedUtils.js';

import EmailCampaign, {
  CAMPAIGN_MODES,
  CAMPAIGN_STATUSES,
} from 'shared/records/EmailCampaign';

import {
  EmailCampaignsSource,
  EmailTemplatesSource,
  StaffSource,
} from 'sources';

import { EmailTemplatesDataStore } from 'dataStores';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';

import MarketingEmailCampaignsActions from './Actions';

class MarketingEmailCampaignsStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.debouncedSearchCampaigns = debounce(this.listCampaigns, 500);
    this.bindListeners({
      openDrawer: MarketingEmailCampaignsActions.openMarketingCampaignDrawer,
      closeDrawer: MarketingEmailCampaignsActions.closeMarketingCampaignDrawer,

      handleFieldChange: MarketingEmailCampaignsActions.handleFieldChange,
      handlePageSelect: MarketingEmailCampaignsActions.handlePageSelect,

      toggleMarketingEmailEdit:
        MarketingEmailCampaignsActions.toggleMarketingEmailEdit,
      toggleMarketingEmailPreview:
        MarketingEmailCampaignsActions.toggleMarketingEmailPreview,
      toggleSendLater: MarketingEmailCampaignsActions.toggleSendLater,

      listContactGroups: MarketingEmailCampaignsActions.listContactGroups,
      listContactGroupsSuccess:
        MarketingEmailCampaignsActions.listContactGroupsSuccess,
      listContactGroupsError:
        MarketingEmailCampaignsActions.listContactGroupsError,

      listEmailTemplates:
        MarketingEmailCampaignsActions.listMarketingEmailTemplates,
      listEmailTemplatesSuccess:
        MarketingEmailCampaignsActions.listMarketingEmailTemplatesSuccess,
      listEmailTemplatesError:
        MarketingEmailCampaignsActions.listMarketingEmailTemplatesError,

      listStaff: MarketingEmailCampaignsActions.listStaff,
      listStaffSuccess: MarketingEmailCampaignsActions.listStaffSuccess,
      listStaffError: MarketingEmailCampaignsActions.listStaffError,

      createMarketingCampaign:
        MarketingEmailCampaignsActions.createMarketingCampaign,
      createMarketingCampaignSuccess:
        MarketingEmailCampaignsActions.createMarketingCampaignSuccess,
      createMarketingCampaignError:
        MarketingEmailCampaignsActions.createMarketingCampaignError,

      listCampaigns: MarketingEmailCampaignsActions.listMarketingCampaigns,
      listCampaignsSuccess:
        MarketingEmailCampaignsActions.listMarketingCampaignsSuccess,
      listCampaignsError:
        MarketingEmailCampaignsActions.listMarketingCampaignsError,

      deleteCampaign: MarketingEmailCampaignsActions.deleteMarketingCampaign,
      deleteCampaignSuccess:
        MarketingEmailCampaignsActions.deleteMarketingCampaignSuccess,
      deleteCampaignError:
        MarketingEmailCampaignsActions.deleteMarketingCampaignError,

      cancelCampaign: MarketingEmailCampaignsActions.cancelMarketingCampaign,
      cancelCampaignSuccess:
        MarketingEmailCampaignsActions.cancelMarketingCampaignSuccess,
      cancelCampaignError:
        MarketingEmailCampaignsActions.cancelMarketingCampaignError,

      searchCampaigns: MarketingEmailCampaignsActions.searchMarketingCampaigns,

      exportMarketingCampaigns:
        MarketingEmailCampaignsActions.exportMarketingCampaigns,
      exportMarketingCampaignsSuccess:
        MarketingEmailCampaignsActions.exportMarketingCampaignsSuccess,
      exportMarketingCampaignsError:
        MarketingEmailCampaignsActions.exportMarketingCampaignsError,

      toggleFiltersDrawer: MarketingEmailCampaignsActions.toggleFiltersDrawer,
      handleFilterChange: MarketingEmailCampaignsActions.handleFilterChange,
      applyFilters: MarketingEmailCampaignsActions.applyFilters,
      removeFilter: MarketingEmailCampaignsActions.removeFilter,
      resetFilters: MarketingEmailCampaignsActions.resetFilters,

      fetchTemplatePreviewSuccess:
        MarketingEmailCampaignsActions.fetchTemplatePreviewSuccess,
      fetchTemplatePreviewError:
        MarketingEmailCampaignsActions.fetchTemplatePreviewError,
    });
  }

  reset() {
    this.resetDrawersStates();
    this.filtersDrawerOpen = false;
    this.isExportingCampaigns = false;
    this.filters = Map();
    this.appliedFilters = Map();
    this.search = '';
    this.emailCampaignsIds = OrderedSet();
    this.pagination = Map({
      campaigns: Map({
        page: 1,
        perPage: 15,
        totalCount: 0,
      }),
      contactGroups: Map({
        page: 1,
        perPage: 50,
        totalCount: 0,
      }),
      staff: Map({
        page: 1,
        perPage: 50,
        totalCount: 0,
      }),
      templates: Map({
        page: 1,
        perPage: 50,
        totalCount: 0,
      }),
    });
    this.loading = Map({
      campaigns: false,
      contactGroups: false,
      staff: false,
      templates: false,
    });
  }

  resetDrawersStates() {
    this.isLoading = false;
    this.isUpdating = false;
    this.drawerOpen = false;
    this.emailTemplateDrawerOpen = false;
    this.emailTemplatePreview = false;
    this.templatePreviewLoading = false;
    this.mode = CAMPAIGN_MODES.CREATE;
    this.templatePreview = null;
    this.campaign = new EmailCampaign();
    this.emailTemplatesIds = OrderedSet();
    this.staffIds = OrderedSet();
    this.contactGroups = [];
    this.sendLater = false;
  }

  openDrawer({ campaign, mode = CAMPAIGN_MODES.CREATE }) {
    this.drawerOpen = true;
    this.mode = mode;

    if (campaign) {
      this.campaign = campaign;
      this.campaign = this.campaign.set(
        'scheduled_at',
        this.campaign.scheduledAt
      );
      this.sendLater = Boolean(this.campaign.scheduledAt);
    } else {
      this.campaign = this.campaign
        .set('scheduled_at', moment())
        .set('send_date', moment())
        .set('send_time', moment());
      this.sendLater = false;
    }

    this.fetchTemplatePreview();
    this.listContactGroups();
    this.listEmailTemplates();
    this.listStaff();
  }

  closeDrawer() {
    this.resetDrawersStates();
    this.pagination = this.pagination
      .set('templates', Map({ page: 1, perPage: 50, totalCount: 0 }))
      .set('staff', Map({ page: 1, perPage: 50, totalCount: 0 }))
      .set('contactGroups', Map({ page: 1, perPage: 50, totalCount: 0 }));
  }

  toggleFiltersDrawer() {
    this.filtersDrawerOpen = !this.filtersDrawerOpen;
  }

  handleFilterChange({ field, value }) {
    this.filters = this.filters.set(field, value);
  }

  applyFilters() {
    this.pagination = this.pagination.setIn(['campaigns', 'page'], 1);
    this.appliedFilters = this.filters;
    this.listCampaigns();
  }

  removeFilter(field) {
    this.filters = this.filters.delete(field);
    this.appliedFilters = this.appliedFilters.delete(field);
    this.listCampaigns();
  }

  resetFilters() {
    this.filters = Map();
    this.appliedFilters = Map();
    this.listCampaigns({});
  }

  toggleSendLater() {
    this.sendLater = !this.sendLater;

    const scheduledAt = this.sendLater ? moment() : null;

    this.campaign = this.campaign
      .set('scheduled_at', scheduledAt)
      .set('send_date', scheduledAt)
      .set('send_time', scheduledAt);
  }

  toggleMarketingEmailPreview() {
    this.emailTemplatePreview = !this.emailTemplatePreview;
  }

  toggleMarketingEmailEdit({ templateId }) {
    this.campaign = this.campaign.validate(this.sendLater);

    if (!this.campaign.isValid) {
      return;
    }

    const campaignTemplateBody = this.campaign.get('body');
    const campaignTemplateSubject = this.campaign.get('subject');

    const { emailTemplates } = EmailTemplatesDataStore.getState();
    const template = emailTemplates.get(templateId);

    this.campaign = this.campaign
      .set('body', campaignTemplateBody || template.body)
      .set('subject', campaignTemplateSubject || template.subject);

    this.emailTemplateDrawerOpen = !this.emailTemplateDrawerOpen;
  }

  handleFieldChange([field, value]) {
    if (typeof field === 'string') {
      this.campaign = this.campaign.set(field, value);
    } else {
      this.campaign = this.campaign.setIn(field, value);

      if (field.includes('email_template')) {
        this.campaign = this.campaign.set('body', '').set('subject', '');
      }
    }

    if (field === 'send_time' || field === 'send_date') {
      this.campaign = this.campaign.set(
        'scheduled_at',
        this.campaign.scheduledAt
      );
    }

    if (!this.campaign.isValid) {
      this.campaign = this.campaign.validate(this.sendLater);
    }
  }

  fetchTemplatePreview() {
    this.templatePreviewLoading = true;

    EmailTemplatesSource.fetchTemplatePreview({
      success: MarketingEmailCampaignsActions.fetchTemplatePreviewSuccess,
      error: MarketingEmailCampaignsActions.fetchTemplatePreviewError,
    });
  }

  fetchTemplatePreviewSuccess(templatePreview) {
    this.templatePreview = templatePreview;
    this.templatePreviewLoading = false;
  }

  fetchTemplatePreviewError(...args) {
    this.templatePreviewLoading = false;
    this.notifyError('Error fetching template preview', args);
  }

  listContactGroups() {
    this.loading = this.loading.set('contactGroups', true);

    uhApiClient.get({
      url: '/contact_groups',
      data: {
        page: this.pagination.getIn(['contactGroups', 'page']),
        per_page: this.pagination.getIn(['contactGroups', 'perPage']),
      },
      success: MarketingEmailCampaignsActions.listContactGroupsSuccess,
      error: MarketingEmailCampaignsActions.listContactGroupsError,
    });
  }

  listContactGroupsSuccess({ contact_groups: fetchedGroups }) {
    const newGroups = fetchedGroups.map(cg => ({
      value: cg.id,
      label: cg.name,
    }));

    this.contactGroups = [
      ...new OrderedSet([...this.contactGroups, ...newGroups]),
    ];
    this.loading = this.loading.set('contactGroups', false);
  }

  listContactGroupsError(...args) {
    this.loading = this.loading.set('contactGroups', false);
    this.notifyError('error while listing contact groups', args);
  }

  listEmailTemplates() {
    this.loading = this.loading.set('templates', true);

    EmailTemplatesSource.list({
      params: {
        page: this.pagination.getIn(['templates', 'page']),
        per_page: this.pagination.getIn(['templates', 'perPage']),
        sort_by: 'subject',
        sort_direction: 'asc',
      },
      success:
        MarketingEmailCampaignsActions.listMarketingEmailTemplatesSuccess,
      error: MarketingEmailCampaignsActions.listMarketingEmailTemplatesError,
    });
  }

  listEmailTemplatesSuccess({
    email_templates: emailTemplates,
    page,
    perPage,
    totalCount,
  }) {
    this.emailTemplatesIds = this.emailTemplatesIds.merge(
      OrderedSet(emailTemplates.map(template => template.id))
    );

    if (page * perPage < totalCount) {
      this.pagination = this.pagination.setIn(['templates', 'page'], page);
      this.listEmailTemplates();
      return;
    }

    this.loading = this.loading.set('templates', false);
  }

  listEmailTemplatesError(...args) {
    this.loading = this.loading.set('templates', false);
    this.notifyError('Error listing email templates', args);
  }

  listStaff() {
    this.loading = this.loading.set('staff', true);

    StaffSource.list({
      params: {
        access_revoked: false,
        page: this.pagination.getIn(['staff', 'page']),
        per_page: this.pagination.getIn(['staff', 'perPage']),
      },
      success: MarketingEmailCampaignsActions.listStaffSuccess,
      error: MarketingEmailCampaignsActions.listStaffError,
    });
  }

  listStaffSuccess({ staff: fetchedStaff, page, perPage, totalCount }) {
    this.staffIds = this.staffIds.merge(fetchedStaff.map(staff => staff.id));

    if (page * perPage < totalCount) {
      this.pagination = this.pagination.setIn(['staff', 'page'], page + 1);
      this.listStaff();
      return;
    }

    this.loading = this.loading.set('staff', false);
  }

  listStaffError(...args) {
    this.loading = this.loading.set('staff', false);
    this.notifyError('Error listing staff', args);
  }

  createMarketingCampaign(campaign) {
    let updatedCampaign = campaign;

    const campaignStatus = campaign.get('status');
    const scheduledAt = campaign.get('scheduled_at');
    const isScheduled = campaignStatus === CAMPAIGN_STATUSES.SCHEDULED;

    if (isScheduled && !scheduledAt && !this.sendLater) {
      updatedCampaign = campaign.set('scheduled_at', moment());
    }

    this.isUpdating = true;

    if (this.mode === CAMPAIGN_MODES.CREATE) {
      EmailCampaignsSource.create({
        campaign: updatedCampaign.toServer(),
        success: MarketingEmailCampaignsActions.createMarketingCampaignSuccess,
        error: MarketingEmailCampaignsActions.createMarketingCampaignError,
      });
    } else {
      EmailCampaignsSource.update({
        campaign: updatedCampaign.toServer(),
        success: MarketingEmailCampaignsActions.createMarketingCampaignSuccess,
        error: MarketingEmailCampaignsActions.createMarketingCampaignError,
      });
    }
  }

  createMarketingCampaignSuccess(campaign) {
    let messageId =
      this.mode === CAMPAIGN_MODES.CREATE
        ? 'containers.marketing.campaigns.MarketingCampaigns.create_success'
        : 'containers.marketing.campaigns.MarketingCampaigns.update_success';

    if (campaign?.status === CAMPAIGN_STATUSES.DRAFT) {
      messageId =
        'containers.marketing.campaigns.MarketingCampaigns.save_draft';
    }

    const message = new TranslatableMessage({ id: messageId });

    this.closeDrawer();
    this.listCampaigns();

    MessageWindowActions.addMessage.defer(message);
  }

  createMarketingCampaignError(...args) {
    this.resetDrawersStates();
    this.notifyError('error creating marketing campaign', args);
  }

  deleteCampaign(campaignId) {
    this.isUpdating = true;

    EmailCampaignsSource.remove({
      campaignId,
      success: MarketingEmailCampaignsActions.deleteMarketingCampaignSuccess,
      error: MarketingEmailCampaignsActions.deleteMarketingCampaignError,
    });
  }

  deleteCampaignSuccess() {
    const message = new TranslatableMessage({
      id: 'containers.marketing.campaigns.MarketingCampaigns.delete_success',
    });

    MessageWindowActions.addMessage.defer(message);

    this.resetDrawersStates();
    this.listCampaigns();
  }

  deleteCampaignError(...args) {
    this.resetDrawersStates();
    this.notifyError('error deleting marketing campaign', args);
  }

  cancelCampaign(campaignId) {
    this.isUpdating = true;

    EmailCampaignsSource.cancel({
      campaignId,
      success: MarketingEmailCampaignsActions.cancelMarketingCampaignSuccess,
      error: MarketingEmailCampaignsActions.cancelMarketingCampaignError,
    });
  }

  cancelCampaignSuccess() {
    const message = new TranslatableMessage({
      id: 'containers.marketing.campaigns.MarketingCampaigns.cancel_success',
    });

    MessageWindowActions.addMessage.defer(message);

    this.resetDrawersStates();
    this.listCampaigns();
  }

  cancelCampaignError(...args) {
    this.resetDrawersStates();
    this.notifyError('error canceling marketing campaign', args);
  }

  getParams() {
    const filters = {
      status: this.appliedFilters.get('status', null),
      created_at_max: this.appliedFilters.get('date_created')?.to || null,
      created_at_min: this.appliedFilters.get('date_created')?.from || null,
      scheduled_at_max: this.appliedFilters.get('sent_at')?.to || null,
      scheduled_at_min: this.appliedFilters.get('sent_at')?.from || null,
    };

    const params = {
      search: this.search,
      page: this.pagination.getIn(['campaigns', 'page']),
      per_page: this.pagination.getIn(['campaigns', 'perPage']),
      ...filters,
    };

    return params;
  }

  listCampaigns() {
    this.loading = this.loading.set('campaigns', true);

    EmailCampaignsSource.list({
      params: this.getParams(),
      success: MarketingEmailCampaignsActions.listMarketingCampaignsSuccess,
      error: MarketingEmailCampaignsActions.listMarketingCampaignsError,
    });
  }

  listCampaignsSuccess({ campaigns, page, perPage, totalCount }) {
    this.emailCampaignsIds = OrderedSet(campaigns.map(campaign => campaign.id));
    this.pagination = this.pagination
      .setIn(['campaigns', 'page'], page)
      .setIn(['campaigns', 'perPage'], perPage)
      .setIn(['campaigns', 'totalCount'], totalCount);
    this.loading = this.loading.set('campaigns', false);
  }

  listCampaignsError(...args) {
    this.loading = this.loading.set('campaigns', false);
    this.notifyError('error listing marketing campaigns', args);
  }

  exportMarketingCampaigns() {
    this.isExportingCampaigns = true;
    EmailCampaignsSource.downloadCsv({
      params: this.getParams(),
      success: MarketingEmailCampaignsActions.exportMarketingCampaignsSuccess,
      error: MarketingEmailCampaignsActions.exportMarketingCampaignsError,
    });
  }

  exportMarketingCampaignsSuccess(data) {
    this.isExportingCampaigns = false;
    downloadFile({
      data,
      fileName: 'campaigns_list.csv',
    });
  }

  exportMarketingCampaignsError(...args) {
    this.isExportingCampaigns = false;
    this.notifyError('error downloading list', args);
  }

  searchCampaigns(search) {
    this.search = search;
    this.loading = this.loading.set('campaigns', true);
    this.debouncedSearchCampaigns();
  }

  handlePageSelect([page]) {
    this.pagination = this.pagination.setIn(['campaigns', 'page'], page);
    this.listCampaigns();
  }
}

export default alt.createStore(
  MarketingEmailCampaignsStore,
  'MarketingEmailCampaignsStore'
);
