import moment from 'moment-timezone';
import { Record, Map } from 'immutable';

import FieldErrors from 'shared/records/FieldErrors.jsx';
import ContactGroup from 'shared/records/ContactGroup.jsx';
import EmailTemplate from 'shared/records/EmailTemplate';

export const CAMPAIGN_MODES = {
  CREATE: 'create',
  EDIT: 'edit',
  VIEW: 'view',
};

export const CAMPAIGN_STATUSES = {
  SCHEDULED: 'scheduled',
  SENT: 'sent',
  CANCELLED: 'cancelled',
  DRAFT: 'draft',
};

class EmailCampaign extends Record({
  id: null,
  title: '',
  body: '',
  subject: '',
  status: 'scheduled',
  created_at: null,
  scheduled_at: null,
  contact_group: new ContactGroup(),
  email_template: new EmailTemplate(),
  reply_to: Map({
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    type: 'Client',
    user_id: null,
    uuid: null,
    created_at: null,
    updated_at: null,
  }),
  // this field is used in the creation form
  send_date: null,
  send_time: null,
  errors: new FieldErrors(),
}) {
  constructor(obj = {}, options = {}) {
    const objWithCustomFields = {
      reply_to: Map(obj.reply_to),
      contact_group: new ContactGroup(obj.contact_group),
      email_template: new EmailTemplate(obj.email_template),
      send_date: obj.scheduled_at ? moment(obj.scheduled_at) : null,
      send_time: obj.scheduled_at ? moment(obj.scheduled_at) : null,
    };

    super({ ...obj, ...objWithCustomFields }, options);
  }

  get scheduledAt() {
    if (Boolean(this.send_time) && Boolean(this.send_date)) {
      const hour = this.send_time.hour();
      const minute = this.send_time.minute();
      const second = this.send_time.second();
      const millisecond = this.send_time.millisecond();
      const scheduledAt = this.send_date
        .hour(hour)
        .minute(minute)
        .second(second)
        .millisecond(millisecond);

      return scheduledAt;
    }
    return null;
  }

  get isValid() {
    return this.errors.isEmpty();
  }

  validate(isScheduleLater = true) {
    let errors = this.errors.clear();

    if (!this.title) {
      errors = errors.add('title', 'records.errors.required');
    }

    if (!this.reply_to.get('id')) {
      errors = errors.add('reply_to', 'records.errors.required');
    }

    if (!this.contact_group.id) {
      errors = errors.add('contact_group', 'records.errors.required');
    }

    if (!this.email_template.id) {
      errors = errors.add('email_template', 'records.errors.required');
    }

    if (isScheduleLater && !this.send_date) {
      errors = errors.add('send_date', 'records.errors.required');
    }

    if (isScheduleLater && this.send_date && !this.send_date?.isValid()) {
      errors = errors.add('send_date', 'records.errors.date_invalid');
    }

    if (isScheduleLater && !this.send_time) {
      errors = errors.add('send_time', 'records.errors.required');
    }

    if (isScheduleLater && this.send_time && !this.send_time?.isValid()) {
      errors = errors.add('send_time', 'records.errors.time_invalid');
    }

    return this.set('errors', errors);
  }

  toServer() {
    const date = moment(this.scheduled_at).format('YYYY-MM-DDTHH:mm:ss');
    const scheduledAt = moment(date).toISOString();

    return {
      id: this.id,
      title: this.title,
      subject: this.subject,
      body: this.body,
      scheduled_at: scheduledAt,
      contact_group_id: this.contact_group.id,
      email_template_id: this.email_template.id,
      reply_to_id: this.reply_to.get('id'),
      status: this.status || 'scheduled',
    };
  }
}

export default EmailCampaign;
