class MarketingEmailStatisticsActions {
  constructor() {
    this.generateActions(
      'toggleFiltersDrawer',
      'handleFieldChange',
      'handlePageSelect',
      'handleFilterChange',
      'applyFilters',
      'resetFilters',
      'removeFilter',
      'searchMarketingEmailStatistics',
      'listMarketingEmailStatistics',
      'listMarketingEmailStatisticsSuccess',
      'listMarketingEmailStatisticsError'
    );
  }
}

export default alt.createActions(MarketingEmailStatisticsActions);
