import { Record } from 'immutable';

import EmailTemplate from './EmailTemplate';

class EmailCampaignStatistic extends Record({
  id: null,
  title: '',
  created_at: null,
  scheduled_at: null,
  email_template: new EmailTemplate(),
  sent_count: 0,
  opened_count: 0,
  unsubscribed_count: 0,
}) {
  constructor(obj = {}, options = {}) {
    const objWithCustomFields = {
      email_template: new EmailTemplate(obj.email_template),
    };

    super({ ...obj, ...objWithCustomFields }, options);
  }
}

export default EmailCampaignStatistic;
