import { Record } from 'immutable';

import Client from './Client.jsx';
import Order from './Order.js';

class AccountCredit extends Record({
  id: null,
  client_id: null,
  credit_type: null,
  amount: 0,
  created_by_id: null,
  created_by: {},
  order_id: null,
  order: {},
  note: '',
  created_at: null,
  updated_at: null,
}) {
  constructor(obj = {}) {
    super({
      ...obj,
      created_by: new Client(obj.created_by || {}),
      order: new Order({ ...obj.order, order_items: [] }),
    });
  }
}

export default AccountCredit;
