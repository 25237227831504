import { Map } from 'immutable';

import { ClientSource } from 'sources';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import AmusementCreditRedemption from 'shared/records/AmusementCreditRedemption';

import { ClientDataStore } from 'dataStores';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import SwingCreditsDrawerActions from './Actions.js';

export const SWING_DRAWER_TABS = {
  MEMBERSHIP: 'membership',
  SWING_HISTORY: 'swingHistory',
};

class SwingCreditsDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      mounted: SwingCreditsDrawerActions.mounted,
      swingsPageSelected: SwingCreditsDrawerActions.swingsPageSelected,

      fetchClientSuccess: SwingCreditsDrawerActions.fetchClientSuccess,
      fetchClientError: SwingCreditsDrawerActions.fetchClientError,

      listSwings: SwingCreditsDrawerActions.listSwings,
      listSwingsSuccess: SwingCreditsDrawerActions.listSwingsSuccess,
      listSwingsError: SwingCreditsDrawerActions.listSwingsError,
    });
  }

  reset() {
    this.clientLoading = false;
    this.swingsLoading = false;
    this.clientId = null;
    this.swings = [];
    this.pagination = Map({
      swingsHistory: Map({
        page: 1,
        perPage: 15,
        totalCount: 0,
      }),
    });
  }

  mounted({ clientId }) {
    this.clientId = clientId;

    if (!clientId) {
      this.reset();
      return;
    }

    const { clients } = ClientDataStore.getState();
    const client = clients.get(clientId);

    if (!client) {
      this.clientLoading = true;

      ClientSource.fetch({
        id: clientId,
        success: SwingCreditsDrawerActions.fetchClientSuccess,
        error: SwingCreditsDrawerActions.fetchClientError,
      });
    } else {
      this.swingsLoading = true;
      this.listSwings();
    }
  }

  swingsPageSelected([page]) {
    this.pagination = this.pagination.setIn(['swingsHistory', 'page'], page);
    this.listSwings();
  }

  fetchClientSuccess() {
    this.clientLoading = false;
    this.listSwings();
  }

  fetchClientError() {
    this.clientLoading = false;
  }

  listSwings() {
    this.swingsLoading = true;

    uhApiClient.get({
      url: 'amusement/credit_redemptions',
      data: {
        client_id: this.clientId,
        page: this.pagination.getIn(['swingsHistory', 'page']),
        per_page: this.pagination.getIn(['swingsHistory', 'perPage']),
      },
      success: SwingCreditsDrawerActions.listSwingsSuccess,
      error: SwingCreditsDrawerActions.listSwingsError,
    });
  }

  listSwingsSuccess({
    records,
    page,
    per_page: perPage,
    total_count: totalCount,
  }) {
    this.swings = records.map(record => new AmusementCreditRedemption(record));
    this.pagination = this.pagination
      .setIn(['swingsHistory', 'page'], page)
      .setIn(['swingsHistory', 'perPage'], perPage)
      .setIn(['swingsHistory', 'totalCount'], totalCount);
    this.swingsLoading = false;
  }

  listSwingsError(...args) {
    this.swingsLoading = false;
    this.notifyError('Error loading swings history', args);
  }
}

export default alt.createStore(
  SwingCreditsDrawerStore,
  'SwingCreditsDrawerStore'
);
