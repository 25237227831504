/* eslint-disable no-nested-ternary */
import { List, Set, Map } from 'immutable';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import QuickpayModalActions from 'quickpay/actions/QuickpayModalActions.js';
import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';
import RefundModalActions from 'containers/reports/refundModal/Actions';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import CardReaderDropdownActions from 'containers/cardReaderDropdown/Actions';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { CardReaderDataStore } from 'dataStores';

import { PaymentMethodSource } from 'sources';

import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import { PaymentMethod as PaymentMethodRecord } from 'records';
import Order from 'event_mgmt/shared/records/Order.jsx';
import PaymentActions from './Actions';

import {
  CardUsageType,
  PaymentMethod,
  StaffPaymentMethods,
  PaymentServiceResponse,
} from './types';
import { PaymentService } from './services';

class PaymentStore extends UpperHandStore {
  constructor() {
    super();

    this.cardUsage = CardUsageType.ONE_TIME;

    this.reset();
    this.client_payment_methods_order = Map();
    this.result = [];
    this.events = [];
    this.membership = [];
    this.creditPasses = [];
    this.order = List();
    this.bindListeners({
      mounted: PaymentActions.MOUNTED,
      reset: [
        PaymentActions.RESET,
        POSActions.DRAWER_DISMISSED,
        QuickpayModalActions.toggleQuickpayModal,
      ],

      handleChangePaymentMethod: PaymentActions.CHANGE_PAYMENT_METHOD,
      handleChangePaymentOption: PaymentActions.CHANGE_PAYMENT_OPTION,
      handleChangeAchPaymentOption: PaymentActions.CHANGE_ACH_PAYMENT_OPTION,
      handleListCardsError: PaymentActions.LIST_CARDS_ERROR,
      handleListCardsSuccess: PaymentActions.LIST_CARDS_SUCCESS,
      handleUpdateNewCardForm: PaymentActions.UPDATE_NEW_CARD_FORM,
      handleUpdateCardUsage: PaymentActions.UPDATE_CARD_USAGE,
      setPaymentMethods: PaymentActions.SET_PAYMENT_METHODS,

      handleTotalUpdate: QuickpayModalActions.CHANGE_PAYMENT_AMOUNT,
      handleAccountCreditsChange:
        QuickpayModalActions.CHANGE_ACCOUNT_CREDIT_AMOUNT,

      handleQSApplyAccountCredits: QuickScheduleActions.FETCH_CART_SUCCESS,

      handlePaymentServiceResponse: PaymentActions.PAYMENT_SERVICE_RESPONSE,
      handlePurchase: PaymentActions.PURCHASE,

      handleUpdateDiscount: QuickScheduleActions.UPDATE_MANUAL_DISCOUNT,
      handleToggleDiscount: QuickScheduleActions.MANAGE_DISCOUNT,

      handleCouponSuccess: QuickScheduleActions.APPLY_COUPON_SUCCESS,
      handleCouponError: QuickScheduleActions.APPLY_COUPON_ERROR,

      handleAddAddon: QuickpayModalActions.ADD_RETAIL_PRODUCT_SUCCESS,
      handleDeleteAddon: QuickpayModalActions.REMOVE_RETAIL_PRODUCT_SUCCESS,

      handleSelectCardReader: CardReaderDropdownActions.SELECT_CARD_READER,
      handleCardReaderPingSuccess: CardReaderDropdownActions.PING_SUCCESS,

      waiveSuccess: RefundModalActions.waiveSuccess,
    });
  }

  reset() {
    // Payment Methods and Cards
    this.paymentCard = new PaymentMethodRecord();
    this.defaultCard = new PaymentMethodRecord();
    this.defaultAch = new PaymentMethodRecord({ type: PaymentMethod.ACH });
    this.paymentMethods = new List();
    this.paymentMethod = PaymentMethod.NONE;
    this.allowedPaymentMethods = new Set();
    this.disabledPaymentMethods = new Set();
    // Loading states
    this.processingFields = false;
    this.processingPayment = false;
    this.hasProcessed = false;
    this.isLoadingPaymentMethods = true;
    this.isMounted = false;
    this.result = [];
    this.events = [];
    this.order = List();
    this.membership = [];
    this.creditPasses = [];
    // Ids and store states
    this.purchasingUserId = null;
    this.purchaserId = null;
    this.hasAccess = false;
    this.hasFutureBillableItem = false;
    this.hasPreSaleItem = false;
    this.accountCreditAmount = null;
    this.itemId = null;
    this.addonOrderId = null;
    this.initialTotal = null;
    this.total = null;
    this.paymentType = null;
    this.hasAccess = false;
    this.isPingingCardReader = false;
    this.allowAccountCredits = false;

    this.client_payment_methods_order = Map(
      currentCustomer().client_payment_methods
    );
  }

  mounted({
    allowAccountCredits,
    disabledPaymentMethods,
    hasAccess,
    hasFutureBillableItem,
    hasPreSaleItem,
    itemId,
    addonOrderId,
    paymentType,
    purchaserId,
    purchasingUserId,
    successAction,
    total,
    order,
  }) {
    if (this.isMounted || purchaserId === null) return;

    this.reset();
    this.isMounted = true;
    this.isLoadingPaymentMethods = true;
    this.disabledPaymentMethods = new Set(disabledPaymentMethods);

    this.allowAccountCredits = allowAccountCredits;
    this.hasAccess = hasAccess;
    this.hasFutureBillableItem = hasFutureBillableItem;
    this.hasPreSaleItem = hasPreSaleItem;
    this.itemId = itemId;
    this.addonOrderId = addonOrderId;
    this.paymentType = paymentType;
    this.purchaserId = purchaserId;
    this.purchasingUserId = purchasingUserId;
    this.success = successAction;
    this.initialTotal = total;
    this.total = total;
    this.order = order;

    this.setPaymentMethods({ listMethods: true });
  }

  setPaymentMethods({ disabled = [], enabled = [], listMethods = false }) {
    if (enabled.length > 0) {
      this.allowedPaymentMethods = new Set(enabled);
      this.setDefaultPaymentMethod();
      return;
    }

    if (disabled.length > 0) {
      this.disabledPaymentMethods = new Set(disabled);
    }

    if (this.order?.order_items?.size === 1) {
      this.order.order_items.forEach(item => {
        if (item.isMembershipItem()) {
          this.result = this.client_payment_methods_order.get(
            'membership_checkout'
          );
        }
        if (item.isEventItem()) {
          const customerDefinedMethods =
            this.client_payment_methods_order.get('event_checkout');
          const orderable = item.get('orderable');
          const eventDefinedMethods = orderable.get('client_payment_methods');

          this.result =
            eventDefinedMethods.length > 0
              ? eventDefinedMethods
              : customerDefinedMethods;
        }
        if (item.isCreditPassItem()) {
          this.result = this.client_payment_methods_order.get(
            'credit_pass_checkout'
          );
        }
      });
    }

    if (this.order?.order_items?.size > 1) {
      this.order.order_items.forEach(item => {
        if (item.isMembershipItem()) {
          this.membership = this.client_payment_methods_order.get(
            'membership_checkout'
          );
        } else if (item.isEventItem()) {
          const customerDefinedMethods =
            this.client_payment_methods_order?.get('event_checkout');
          const orderable = item.get('orderable');
          const eventDefinedMethods = orderable.get('client_payment_methods');
          const eventPaymentMethods =
            eventDefinedMethods.length > 0
              ? eventDefinedMethods
              : customerDefinedMethods;

          if (this.events.length === 0) {
            this.events = eventPaymentMethods;
          } else {
            this.events = this.events.filter(event =>
              eventPaymentMethods.includes(event)
            );
          }
        } else {
          this.creditPasses = this.client_payment_methods_order?.get(
            'credit_pass_checkout'
          );
        }
      });

      if (
        this.events?.length > 0 &&
        this.membership?.length > 0 &&
        this.creditPasses?.length > 0
      ) {
        this.result = this.events.filter(
          item =>
            this.client_payment_methods_order
              .get('membership_checkout')
              .some(item2 => item === item2) &&
            this.client_payment_methods_order
              .get('credit_pass_checkout')
              .some(item3 => item === item3)
        );
      }

      if (this.events?.length > 0) {
        this.result = [...this.events];
      }

      if (this.membership?.length > 0) {
        this.result = [...this.membership];
      }

      if (this.creditPasses.length > 0) {
        this.result = [...this.creditPasses];
      }

      if (this.events.length > 0 && this.membership.length > 0) {
        this.result = this.events.filter(item =>
          this.membership.some(item2 => item === item2)
        );
      }

      if (this.events.length > 0 && this.creditPasses.length > 0) {
        this.result = this.events.filter(item =>
          this.creditPasses.some(item2 => item === item2)
        );
      }

      if (this.membership.length > 0 && this.creditPasses.length > 0) {
        this.result = this.membership.filter(item =>
          this.creditPasses.some(item2 => item === item2)
        );
      }
    }
    // check client billing settings first
    this.allowedPaymentMethods = Set(this.result);

    // remove methods that are not allowed by current UI (parent component)
    this.allowedPaymentMethods = this.allowedPaymentMethods.subtract(
      this.disabledPaymentMethods
    );

    // staff should have access to all payment methods
    if (currentUser().isStaff()) {
      this.allowedPaymentMethods =
        this.allowedPaymentMethods.union(StaffPaymentMethods);

      // Staff will have access to HandpointCloud if feature flag enabled
      if (currentCustomer().handpoint_cloud_enabled && !this.addonOrderId) {
        this.allowedPaymentMethods = this.allowedPaymentMethods.union([
          PaymentMethod.HANDPOINT_CLOUD,
        ]);
      }

      // Quick Pay should not have Pay Later, even for staff
      if (this.disabledPaymentMethods.has(PaymentMethod.PAY_LATER)) {
        this.allowedPaymentMethods = this.allowedPaymentMethods.subtract([
          PaymentMethod.PAY_LATER,
        ]);
      }
    }

    // pre-sale memberships can only use card all payment methods
    if (this.total === 0 && this.hasPreSaleItem) {
      this.allowedPaymentMethods = new Set([PaymentMethod.CARD]);
    }

    if (this.total === 0 && this.order?.get('prorate_date') !== null) {
      this.allowedPaymentMethods = new Set([PaymentMethod.CARD]);
    }
    if (
      this.total === 0 &&
      this.order?.couponSingleTimeAndMembership() &&
      currentUser().isClient()
    ) {
      this.allowedPaymentMethods = new Set([PaymentMethod.CARD]);
    }

    // allow client to pay balance with card
    if (this.hasAccess && !this.isCartViewing()) {
      this.allowedPaymentMethods = this.allowedPaymentMethods.union([
        PaymentMethod.CARD,
      ]);
    }

    if ((this.hasAccess || currentUser().isClient()) && listMethods) {
      this.listCards();
    } else {
      this.isLoadingPaymentMethods = false;
      this.setDefaultPaymentMethod();
      this.defaultCard = new PaymentMethodRecord();
      this.defaultAch = new PaymentMethodRecord({ type: PaymentMethod.ACH });
    }
  }

  setDefaultPaymentMethod() {
    if (this.allowedPaymentMethods.has(PaymentMethod.CARD)) {
      if (this.allowedPaymentMethods.has(PaymentMethod.HANDPOINT_CLOUD)) {
        this.paymentMethod = PaymentMethod.HANDPOINT_CLOUD;
        this.cardUsage = CardUsageType.ONE_TIME;
      } else if (this.defaultCard && this.defaultCard.info.isUsable()) {
        this.paymentMethod = PaymentMethod.CARD;
        this.paymentCard = this.defaultCard;
        this.cardUsage = CardUsageType.ON_FILE;
      } else {
        this.paymentMethod = PaymentMethod.CARD_NEW;
        this.paymentCard = new PaymentMethodRecord();
        this.cardUsage = CardUsageType.ONE_TIME;
      }
    } else if (this.allowedPaymentMethods.has(PaymentMethod.ACH)) {
      if (this.defaultAch && this.defaultAch.info.isUsable()) {
        this.paymentMethod = PaymentMethod.ACH;
        this.paymentCard = this.defaultAch;
        this.cardUsage = CardUsageType.ON_FILE;
      } else {
        this.paymentMethod = PaymentMethod.ACH_NEW;
        this.paymentCard = new PaymentMethodRecord({ type: PaymentMethod.ACH });
        this.cardUsage = CardUsageType.ONE_TIME;
      }
    } else if (this.allowedPaymentMethods.has(PaymentMethod.PAY_LATER)) {
      this.paymentMethod = PaymentMethod.PAY_LATER;
    } else if (this.allowedPaymentMethods.has(PaymentMethod.CASH)) {
      this.paymentMethod = PaymentMethod.CASH;
    } else if (this.allowedPaymentMethods.has(PaymentMethod.CHECK)) {
      this.paymentMethod = PaymentMethod.CHECK;
    }

    // force to cash if event/item is free, but not a membership
    if (
      this.total === 0 &&
      !(this.hasPreSaleItem || this.hasFutureBillableItem) &&
      this.order?.get('prorate_date') === null &&
      !this.order?.couponSingleTimeAndMembership()
    ) {
      this.paymentMethod = PaymentMethod.CASH;
    }

    this.isLoadingPaymentMethods = false;
  }

  // eslint-disable-next-line class-methods-use-this
  isCartViewing() {
    return window.location.pathname.includes('cart');
  }

  handleTotalUpdate(amount) {
    this.total = amount;
  }

  handleAccountCreditsChange(amount) {
    this.accountCreditAmount = amount;

    if (this.accountCreditAmount === this.total) {
      this.paymentMethod = PaymentMethod.CASH;
    }
  }

  getCardType() {
    return this.cardUsage === CardUsageType.ON_FILE
      ? PaymentMethod.CARD
      : PaymentMethod.CARD_NEW;
  }

  handlePurchase() {
    this.processingFields = true;
    const paymentMethod =
      this.total === 0 &&
      !(this.hasPreSaleItem || this.hasFutureBillableItem) &&
      this.order?.get('prorate_date') === null &&
      !this.order?.couponSingleTimeAndMembership()
        ? PaymentMethod.CASH
        : this.paymentMethod;

    if (
      paymentMethod === PaymentMethod.CARD_NEW ||
      paymentMethod === PaymentMethod.ACH_NEW
    ) {
      const tempCard = this.paymentCard.info.validate(
        currentCustomer().use_stripe
      );

      this.paymentCard = this.paymentCard.set('info', tempCard);

      // early return for local validation
      if (!this.paymentCard.info.isValid()) {
        this.processingFields = false;
        return;
      }
    }

    if (paymentMethod === PaymentMethod.HANDPOINT_CLOUD) {
      const selectedId = window.localStorage.getItem('cardReaderId');

      if (!selectedId) {
        MessageWindowActions.addMessage.defer('No card reader selected.');
        this.processingFields = false;
        return;
      }

      if (this.isPingingCardReader) {
        MessageWindowActions.addMessage.defer(
          'Please wait until card reader status is determined.'
        );
        this.processingFields = false;
        return;
      }

      const { cardReaders } = CardReaderDataStore.getState();

      if (
        cardReaders.find(cr => cr.get('id') === selectedId)?.get('status') !==
        'available'
      ) {
        MessageWindowActions.addMessage.defer('Card reader not available.');
        this.processingFields = false;
        return;
      }
    }

    PaymentService.complete({
      itemId: this.itemId,
      addonOrderId: this.addonOrderId,
      type: this.paymentType,
      paymentRecord: this.paymentCard,
      paymentServiceResponse: PaymentActions.paymentServiceResponse.defer,
      purchaserId: this.purchaserId,
      amount: Number(this.total) > 0 ? this.total : 0,
      paymentMethod,
      use: this.cardUsage,
      accountCreditAmount: this.accountCreditAmount,
    });
  }

  handlePaymentServiceResponse([type, response]) {
    switch (type) {
      case PaymentServiceResponse.PAYMENT_SUCCESS:
        this.processingFields = false;
        this.processingPayment = false;
        this.hasProcessed = true;
        this.success(response);
        break;

      case PaymentServiceResponse.PAYMENT_ERROR:
        this.processingFields = false;
        this.processingPayment = false;
        // Take HttpError class into account
        MessageWindowActions.addMessage.defer(
          response.httpMessage || response.message || response
        );
        break;

      case PaymentServiceResponse.TOKEN_SUCCESS:
        this.processingPayment = true;
        break;

      case PaymentServiceResponse.TOKEN_ERROR:
        this.processingFields = false;
        if (response.message.indexOf('Invalid card number') >= 0) {
          const tempCard = this.paymentCard.info.setError(
            'number',
            'Invalid card number'
          );
          this.paymentCard = this.paymentCard.set('info', tempCard);
        } else {
          MessageWindowActions.addMessage.defer(response);
        }
        break;

      default:
        break;
    }
  }

  handleChangePaymentOption(newOption) {
    this.paymentCard = new PaymentMethodRecord();

    if (newOption === PaymentMethod.CARD_NEW) {
      this.paymentMethod = PaymentMethod.CARD_NEW;
      this.cardUsage = CardUsageType.ONE_TIME;
    } else {
      this.paymentMethod = PaymentMethod.CARD;
      this.paymentCard = this.paymentMethods.find(
        c => c.get('id') === newOption
      );
      this.cardUsage = CardUsageType.ON_FILE;
    }
  }

  handleChangeAchPaymentOption(newOption) {
    this.paymentCard = new PaymentMethodRecord({ type: PaymentMethod.ACH });

    if (newOption === PaymentMethod.ACH_NEW) {
      this.paymentMethod = PaymentMethod.ACH_NEW;
      this.cardUsage = CardUsageType.ONE_TIME;
    } else {
      this.paymentMethod = PaymentMethod.ACH;
      this.paymentCard = this.paymentMethods.find(
        c => c.get('id') === newOption
      );
      this.cardUsage = CardUsageType.ON_FILE;
    }
  }

  handleChangePaymentMethod(newMethod) {
    this.paymentCard = new PaymentMethodRecord({ type: newMethod });

    switch (newMethod) {
      case PaymentMethod.ACH:
        this.cardUsage =
          this.defaultAch && this.defaultAch.info.isUsable()
            ? CardUsageType.ON_FILE
            : CardUsageType.ONE_TIME;
        this.paymentMethod =
          this.defaultAch && this.defaultAch.info.isUsable()
            ? PaymentMethod.ACH
            : PaymentMethod.ACH_NEW;

        if (this.defaultAch && this.defaultAch.info.isUsable()) {
          this.paymentCard = this.defaultAch;
        }

        break;

      case PaymentMethod.CARD:
        this.cardUsage =
          this.defaultCard && this.defaultCard.info.isUsable()
            ? CardUsageType.ON_FILE
            : CardUsageType.ONE_TIME;

        this.paymentMethod =
          this.defaultCard && this.defaultCard.info.isUsable()
            ? PaymentMethod.CARD
            : PaymentMethod.CARD_NEW;

        if (this.defaultCard && this.defaultCard.info.isUsable()) {
          this.paymentCard = this.defaultCard;
        }

        break;

      case PaymentMethod.CARD_NOT_PRESENT:
        this.paymentMethod =
          this.defaultCard && this.defaultCard.info.isUsable()
            ? PaymentMethod.CARD
            : PaymentMethod.CARD_NEW;

        this.cardUsage =
          this.defaultCard && this.defaultCard.info.isUsable()
            ? CardUsageType.ON_FILE
            : CardUsageType.ONE_TIME;

        if (this.defaultCard && this.defaultCard.info.isUsable()) {
          this.paymentCard = this.defaultCard;
        }

        break;

      case PaymentMethod.HANDPOINT_CLOUD:
        this.cardUsage =
          this.cardUsage === CardUsageType.SAVE
            ? CardUsageType.SAVE
            : CardUsageType.ONE_TIME;
      // fall through to set paymentMethod accordingly

      default:
        this.paymentMethod = newMethod;

        break;
    }
  }

  listCards() {
    this.isLoadingPaymentMethods = true;
    return PaymentMethodSource.list({
      userId: this.purchasingUserId,
      success: PaymentActions.listCardsSuccess,
      error: PaymentActions.listCardsError,
    });
  }

  handleListCardsError(...args) {
    this.isLoadingPaymentMethods = false;
    this.notifyError('Error while fetching saved cards', args);
  }

  handleListCardsSuccess({ payment_methods: paymentMethods }) {
    this.paymentMethods = paymentMethods;

    const defaultPayment = paymentMethods.find(card => card.get('default'));

    if (defaultPayment && defaultPayment.get('type') === PaymentMethod.CARD) {
      this.defaultCard = defaultPayment;
    } else if (
      defaultPayment &&
      defaultPayment.get('type') === PaymentMethod.ACH
    ) {
      this.defaultAch = defaultPayment;
    } else {
      this.defaultCard = new PaymentMethodRecord();
      this.defaultAch = new PaymentMethodRecord({ type: PaymentMethod.ACH });
    }

    this.setDefaultPaymentMethod();
  }

  handleUpdateNewCardForm([key, value]) {
    let tempCard = this.paymentCard;

    if (Array.isArray(key)) {
      tempCard = this.paymentCard.info.setIn(key, value);
    } else {
      tempCard = this.paymentCard.info.set(key, value);
    }

    this.paymentCard = this.paymentCard.set('info', tempCard);

    if (this.paymentCard.info.errors.getErrors(key).length) {
      tempCard = this.paymentCard.info.validate();
      this.paymentCard = this.paymentCard.set('info', tempCard);
    }
  }

  handleUpdateCardUsage() {
    if (this.cardUsage === CardUsageType.ONE_TIME) {
      this.cardUsage = CardUsageType.SAVE;
    } else {
      this.cardUsage = CardUsageType.ONE_TIME;
    }
  }

  handleUpdateDiscount(discount = null) {
    if (discount) {
      this.total = discount.applyTo(this.initialTotal);
    } else {
      this.total = this.initialTotal;
    }
  }

  handleToggleDiscount(enabled = false) {
    if (!enabled) this.total = this.initialTotal;
  }

  handleCouponSuccess(order) {
    this.total = order.total;
  }

  handleCouponError() {
    this.total = this.initialTotal;
  }

  handleSelectCardReader() {
    this.isPingingCardReader = true;
  }

  handleCardReaderPingSuccess(cardReader) {
    if (cardReader.get('id') === window.localStorage.getItem('cardReaderId'))
      this.isPingingCardReader = false;
  }

  handleAddAddon(order) {
    this.addonOrderId = order.order_id;

    if (currentCustomer().handpoint_cloud_enabled) {
      this.allowedPaymentMethods = this.allowedPaymentMethods.subtract([
        PaymentMethod.HANDPOINT_CLOUD,
      ]);
    }
    if (this.paymentMethod === PaymentMethod.HANDPOINT_CLOUD) {
      this.setDefaultPaymentMethod();
    }
  }

  handleDeleteAddon() {
    this.addonOrderId = null;

    if (currentCustomer().handpoint_cloud_enabled) {
      this.allowedPaymentMethods = this.allowedPaymentMethods.union([
        PaymentMethod.HANDPOINT_CLOUD,
      ]);
    }
  }

  waiveSuccess({ amount }) {
    this.total -= amount;
  }

  handleQSApplyAccountCredits(order) {
    const fetchedOrder = new Order(order);
    const creditsAmountApplied = fetchedOrder
      .get('order_items')
      .first()
      .get('account_credit_amount');

    this.order = fetchedOrder;
    this.total = fetchedOrder.get('total');
    this.accountCreditAmount = creditsAmountApplied;
  }
}

export default alt.createStore(PaymentStore, 'PaymentStore');
