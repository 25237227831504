import { Iterable, Record } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';

export default class PaymentAch extends Record({
  account_number: '',
  routing_number: '',
  account_holder_name: '',
  city: '',
  street: '',
  zip: '',
  ach_payment_method_id: '',
  last2: '',
  name: '',
  errors: new FieldErrors(),
}) {
  constructor(obj = {}) {
    let newObj;

    if (Iterable.isIterable(obj)) {
      newObj = obj.toJS();
    } else {
      newObj = obj;
    }

    super({ ...newObj });
  }

  set(key, value) {
    return Record.prototype.set.call(this, key, value);
  }

  setError(key, value) {
    const errors = this.errors.add(key, value);

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty();
  }

  isUsable() {
    return (
      (!!this.routing_number && !!this.name && !!this.last2) ||
      this.ach_payment_method_id
    );
  }

  validate() {
    let errors = new FieldErrors();

    if (!this.account_number.length) {
      errors = errors.add('account_number', 'Account number is required');
    }

    if (this.account_number.length < 9 || this.account_number.length > 12) {
      errors = errors.add(
        'account_number',
        'Account number must be between 9 and 12 digits long'
      );
    }

    if (this.account_number === this.routing_number) {
      errors = errors.add(
        'account_number',
        "Account number can't be the same as the routing number"
      );
    }

    if (!this.routing_number.length) {
      errors = errors.add('routing_number', 'Routing number is required');
    }

    if (this.routing_number === this.account_number) {
      errors = errors.add(
        'routing_number',
        "Routing number can't be the same as the account number"
      );
    }

    if (this.routing_number.length !== 9) {
      errors = errors.add(
        'routing_number',
        'Routing number must be 9 digits long'
      );
    }

    if (!this.account_holder_name.length) {
      errors = errors.add(
        'account_holder_name',
        'Account holder name is required'
      );
    }

    if (!this.city.length) {
      errors = errors.add('city', 'City is required');
    }

    if (!this.street.length) {
      errors = errors.add('street', 'Street is required');
    }

    if (!this.zip.length) {
      errors = errors.add('zip', 'Zip is required');
    }

    return this.set('errors', errors);
  }
}
