import { List, Map } from 'immutable';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import {
  Printer,
  NativeWifiPrinter,
  NativeBluetoothPrinter,
} from 'shared/records/Printer.js';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';

import {
  TestLabel,
  TestReceipt,
} from 'shared/components/zebra/ZPL/TestPrints.js';
import ZebraActions from 'shared/actions/ZebraActions.jsx';
import ZebraPrinterSettings from 'shared/records/ZebraPrinterSettings.js';
import ZebraPrinterBluetooth from 'shared/native/zebra_printer/Bluetooth.js';
import ZebraPrinterWifi from 'shared/native/zebra_printer/Wifi.js';

const wifiString = (name, ssid, password) =>
  `^XA^KN${name}^WID^WS${ssid}^WX09,${password}^JUS^XZ~JR`;
const getConfigString = '~HQSN';
const getStatusString = '~HQES';
const url = id => `customers/${id}`;

const dpi = 203;

export const CONNECTION_ERROR = 'connection_error';
export const CUTTER_FAULT = 'cutter_fault';
export const INCORRECT_PRINTHEAD = 'incorrect_printhead';
export const MEDIA_DOOR_OPEN = 'media_door_open';
export const MOTOR_OVERHEATING = 'motor_overheating';
export const READY_TO_PRINT = 'ready_to_print';
export const RIBBON_OUT = 'ribbon_out';
export const PAPER_OUT = 'paper_out';
export const PRINTHEAD_OVERHEATING = 'printhead_overheating';
export const PRINTHEAD_FAULT = 'printhead_fault';
export const PRINTER_PAUSED = 'printer_paused';
export const UNKNOWN_ERROR = 'unknown_error';

export const DEFAULT = 'default';
export const NETWORK_VIEW = 'network_view';
export const MEDIA_VIEW = 'media_view';
export const TEST_VIEW = 'test_view';

export const BLUETOOTH = 'bluetooth';

function t(id) {
  return new TranslatableMessage({ id, filename: __filenamespace });
}

class ZebraStore extends UpperHandStore {
  constructor() {
    super();
    this.drawerOpen = false;
    this.networkOpen = false;
    this.mediaOpen = false;
    this.confirmationOpen = false;

    this.printers = List();
    this.defaultPrinter = null;
    this.printing = false;
    this.isLoadingDevices = false;
    this.isLoadingStatus = false;
    this.zebraSettings = new ZebraPrinterSettings();
    this.wifiSettings = Map({ ssid: '', password: '' });
    this.ok = false;
    this.statuses = List();

    this.bluetoothSearch = false;
    this.hasAppInstalled = false;

    this.bindListeners({
      closePrintSettings: ZebraActions.CLOSE_PRINT_SETTINGS,
      closeConfirmation: ZebraActions.CLOSE_CONFIRMATION,
      openPrintSettings: ZebraActions.OPEN_PRINT_SETTINGS,
      changeView: ZebraActions.CHANGE_VIEW,
      openConfirmation: ZebraActions.OPEN_CONFIRMATION,

      listFinished: ZebraActions.LIST_FINISHED,
      statusFinished: ZebraActions.STATUS_FINISHED,
      printFinished: ZebraActions.PRINT_FINISHED,
      loadPrinters: ZebraActions.LOAD_PRINTERS,
      printReceipt: ZebraActions.PRINT_RECEIPT,
      printLabel: ZebraActions.PRINT_LABEL,
      printTestReceipt: ZebraActions.PRINT_TEST_RECEIPT,
      printTestLabel: ZebraActions.PRINT_TEST_LABEL,
      updatePrinterSN: ZebraActions.UPDATE_PRINTER_SN,
      handleReadPrinterStatus: ZebraActions.READ_PRINTER_STATUS,
      setDefaultPrinter: ZebraActions.SET_DEFAULT_PRINTER,
      setupWifi: ZebraActions.SETUP_WIFI,

      handleFetch: ZebraActions.FETCH,
      handleUpdate: ZebraActions.UPDATE,
      updatePrinterSettings: ZebraActions.UPDATE_PRINTER_SETTINGS,
      updateWifiSettings: ZebraActions.UPDATE_WIFI_SETTINGS,
      successFetch: ZebraActions.SUCCESS_FETCH,
      error: ZebraActions.ERROR,
    });
  }

  updatePrinterSettings(state) {
    this.zebraSettings = this.zebraSettings.merge(state);
  }

  updateWifiSettings(state) {
    this.wifiSettings = this.wifiSettings.merge(state);
  }

  listFinished() {
    this.isLoadingDevices = false;
  }

  printFinished() {
    this.printing = false;
  }

  async checkForDefault(printers) {
    let printer = printers.last();
    let newPrinters = printers.pop();
    this.isloadingDevices = true;

    while (printer !== null) {
      /* eslint-disable no-await-in-loop */
      try {
        await printer.connectPrinter();
        const data = await printer.sendThenReadZPL(getConfigString);
        if (data.substr(54, 12) === this.zebraSettings.sn) {
          this.showError(t('.printer_connected'));
          this.defaultPrinter = printer;
          this.zebraSettings = this.zebraSettings.updateIP(printer.name);
          this.handleUpdate();
          return true;
        }
      } catch (e) {
        this.showError(e.message);
      }
      try {
        await printer.disconnectPrinter();
      } catch (e) {
        /* If printer fails disconnect do nothing */
      }

      printer = newPrinters.last();
      newPrinters = newPrinters.pop();
    }
    /* eslint-enable no-await-in-loop */

    return false;
  }

  statusFinished() {
    this.isLoadingStatus = false;
  }

  // eslint-disable-next-line class-methods-use-this
  showError(error) {
    MessageWindowActions.addMessage.defer(error);
  }

  async handleReadPrinterStatus() {
    this.isLoadingStatus = true;
    try {
      await this.readPrinterStatus();
    } catch (error) {
      this.showError(error.message);
    }
  }

  async readPrinterStatus() {
    const response = await this.defaultPrinter.sendThenReadZPL(getStatusString);
    this.showError(response);
    this.deviceStatus(response);
  }

  async setDefaultPrinter(value) {
    this.defaultPrinter = value;

    if (!this.defaultPrinter) {
      this.zebraSettings = this.zebraSettings.updateIP('');
      this.zebraSettings = this.zebraSettings.updateSn('');
      return;
    }
    this.zebraSettings = this.zebraSettings.updateIP(value.name);

    this.printing = true;

    try {
      await this.defaultPrinter.connectPrinter();
      await this.handleReadPrinterStatus();
    } catch (err) {
      this.statuses = List([CONNECTION_ERROR]);
    } finally {
      await this.defaultPrinter.disconnectPrinter();

      ZebraActions.printFinished.defer();
      ZebraActions.statusFinished.defer();
    }
  }

  async setupWifi() {
    this.handleUpdate();
    this.changeView(DEFAULT);

    await this.defaultPrinter.connectPrinter();

    try {
      const zpl = wifiString(
        this.zebraSettings.name,
        this.wifiSettings.get('ssid'),
        this.wifiSettings.get('password')
      );
      await this.defaultPrinter.sendZPL(zpl);
      this.destroyNetworkInfo();
      await this.defaultPrinter.disconnectPrinter();
    } catch (error) {
      this.showError(error);
      await this.defaultPrinter.disconnectPrinter();
    }
  }

  async updatePrinterSN() {
    if (!this.defaultPrinter) {
      this.zebraSettings = this.zebraSettings.updateSn('');
      this.handleUpdate();
      return;
    }

    try {
      await this.defaultPrinter.connectPrinter();
      const response = await this.defaultPrinter.sendThenReadZPL(
        getConfigString
      );
      await this.defaultPrinter.disconnectPrinter();
      this.zebraSettings = this.zebraSettings.updateSn(response.substr(54, 12));
      this.handleUpdate();
    } catch (_) {
      this.showError(t('.failed_to_get_sn'));
    }
  }

  async loadPrinters() {
    this.isLoadingDevices = true;
    this.bluetoothSearch = false;
    this.hasAppInstalled = false;
    this.defaultPrinter = null;
    this.printers = List();

    const connectedViaWifi = await this.loadWifiPrinters();
    if (!connectedViaWifi) {
      await this.loadBluetoothPrinters();
    }

    try {
      await this.loadBrowserPrinters();
    } catch (_) {
      /* For when browserprint returns and empty list */
    }

    if (
      this.printers.size > 0 &&
      this.zebraSettings.sn !== '' &&
      !this.bluetoothSearch
    ) {
      await this.checkForDefault(this.printers.slice(0));
    }

    if (
      this.zebraSettings.sn !== '' &&
      !this.defaultPrinter &&
      this.hasAppInstalled
    ) {
      this.showError(t('.failed_to_connect'));
    }

    ZebraActions.listFinished.defer();
  }

  async loadBrowserPrinters() {
    return new Promise((resolve, reject) => {
      window.BrowserPrint.getLocalDevices(
        discoveredPrinters => {
          if (Object.values(discoveredPrinters).length > 0) {
            this.printers = List(
              Object.values(discoveredPrinters)[0].map(
                p => new Printer(p, p.name)
              )
            );
            this.hasAppInstalled = true;
            resolve();
          } else {
            this.hasAppInstalled = true;
            reject();
          }
        },
        () => {
          reject();
        }
      );
    });
  }

  async loadWifiPrinters() {
    try {
      await ZebraPrinterWifi.disconnect();
    } catch (error) {
      return false;
    }

    try {
      const discoveredPrinters = await ZebraPrinterWifi.discover(5);

      if (discoveredPrinters.length > 0) {
        const printerObjects = discoveredPrinters.map(
          p => new NativeWifiPrinter(p, p)
        );
        this.printers = List(printerObjects);
        this.hasAppInstalled = true;
        return true;
      }
      this.showError(t('.printer_not_found_bluetooth'));
      return false;
    } catch (error) {
      this.showError(error.message);
      return false;
    }
  }

  async loadBluetoothPrinters() {
    try {
      await ZebraPrinterBluetooth.disconnect();
    } catch (error) {
      return false;
    }
    try {
      const discoveredPrinters = await ZebraPrinterBluetooth.discover();
      const printerObjects = discoveredPrinters.map(
        p => new NativeBluetoothPrinter(p, p)
      );
      this.printers = List(printerObjects);
      this.bluetoothSearch = true;
      return true;
    } catch (error) {
      this.showError(error.message);
      return false;
    }
  }

  async printReceipt(data) {
    this.genericPrint(data);
  }

  async printLabel(data) {
    this.genericPrint(data);
  }

  async printTestReceipt() {
    this.genericPrint(
      `^XA^LL600^${this.zebraSettings.widthReceipt * dpi}^MNN${TestReceipt}`
    );
  }

  async printTestLabel() {
    this.genericPrint(
      `^XA^MNA^PW${this.zebraSettings.widthLabel * dpi}^CI28${TestLabel}`
    );
  }

  async genericPrint(data) {
    if (this.defaultPrinter === null) {
      this.showError(t('.no_printer_selected'));

      return;
    }
    this.printing = true;

    try {
      await this.defaultPrinter.connectPrinter();
      await this.handleReadPrinterStatus();
      if (this.ok) {
        await this.defaultPrinter.sendZPL(data);
      } else {
        this.showError(t('.printer_error'));
      }
      await this.defaultPrinter.disconnectPrinter();
    } catch (error) {
      this.showError(error.message);
      this.loadPrinters();
    }
    ZebraActions.printFinished.defer();
  }

  openPrintSettings() {
    this.drawerOpen = true;
  }

  openConfirmation() {
    this.confirmationOpen = true;
  }

  closeConfirmation() {
    this.confirmationOpen = false;
  }

  closePrintSettings() {
    this.drawerOpen = false;
    this.destroyNetworkInfo();
  }

  changeView(page) {
    switch (page) {
      case DEFAULT:
        this.networkOpen = false;
        this.mediaOpen = false;
        this.confirmationOpen = false;
        break;
      case NETWORK_VIEW:
        this.networkOpen = true;
        this.mediaOpen = false;
        break;
      case MEDIA_VIEW:
        this.networkOpen = false;
        this.mediaOpen = true;
        break;
      case TEST_VIEW:
        this.networkOpen = false;
        this.mediaOpen = false;
        break;
      default:
        this.networkOpen = false;
        this.mediaOpen = false;
        break;
    }
    this.veiw = page;
  }

  deviceStatus(text) {
    const statuses = [];
    let ok = false;
    const isError = text.charAt(70);
    const media = text.charAt(88);
    const head = text.charAt(87);
    const pause = text.charAt(84);

    // check each flag that prevents printing
    if (text === BLUETOOTH) {
      statuses.push(BLUETOOTH);
    } else {
      if (isError === '0') {
        ok = true;
        statuses.push(READY_TO_PRINT);
      }
      if (media === '1') {
        statuses.push(PAPER_OUT);
      }
      if (media === '2') {
        statuses.push(RIBBON_OUT);
      }
      if (media === '4') {
        statuses.push(MEDIA_DOOR_OPEN);
      }
      if (media === '8') {
        statuses.push(CUTTER_FAULT);
      }
      if (head === '1') {
        statuses.push(PRINTHEAD_OVERHEATING);
      }
      if (head === '2') {
        statuses.push(MOTOR_OVERHEATING);
      }
      if (head === '4') {
        statuses.push(PRINTHEAD_FAULT);
      }
      if (head === '8') {
        statuses.push(INCORRECT_PRINTHEAD);
      }
      if (pause === '1') {
        statuses.push(PRINTER_PAUSED);
      }
      if (!ok && statuses.Count === 0) {
        statuses.push(UNKNOWN_ERROR);
        ok = false;
      }
    }
    this.ok = ok;
    this.statuses = List(statuses);
    ZebraActions.statusFinished.defer();
  }

  handleFetch() {
    this.isLoadingDevices = true;
    return uhApiClient.get({
      url: url(currentCustomer().id),
      data: { fields: ['printer_settings'] },
      success: ZebraActions.successFetch,
      error: ZebraActions.error,
    });
  }

  handleUpdate() {
    return uhApiClient.post({
      url: url(`${currentCustomer().id}/printer_settings`),
      data: JSON.stringify({
        attributes: {
          id: currentCustomer().id,
          printer_settings: this.zebraSettings.toServer(),
        },
        fields: ['printer_settings'],
      }),
      error: ZebraActions.error,
    });
  }

  successFetch(data) {
    this.zebraSettings = new ZebraPrinterSettings(data.printer_settings);
    if (this.zebraSettings.ip_address !== '') {
      this.defaultPrinter = new NativeWifiPrinter(
        this.zebraSettings.ip_address,
        this.zebraSettings.ip_address
      );
      this.printers = List([this.defaultPrinter]);
    }
    ZebraActions.listFinished.defer();
  }

  error(...args) {
    this.notifyError(t('.connection_error'), args);
    ZebraActions.listFinished.defer();
  }

  destroyNetworkInfo() {
    this.wifiSettings = Map({ ssid: '', password: '' });
  }
}

export default alt.createStore(ZebraStore, 'ZebraStore');
