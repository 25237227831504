export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'Upper Hand',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.header': 'Dominate',
  'client_theme.ThemeSidebar.subHeader': 'your field.',
  'client_theme.ThemeSidebar.mission': 'Upper Hand partners',
  'client_theme.ThemeSidebar.mission1': 'with sports and Fitness',
  'client_theme.ThemeSidebar.mission2': 'organizations to help manage',
  'client_theme.ThemeSidebar.mission3': 'and grow their business.',
  'client_theme.ThemeSidebar.learn_more': ' ', // leave a space here so we don't display this for main instance.
};
