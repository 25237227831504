import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Drawer, Typography } from '@upperhand/playmaker';

import HistoryItem from 'containers/clientProfile/components/Drawers/SwingCreditsDrawer/components/HistoryItem.jsx';
import EmptyState from 'containers/clientProfile/components/Drawers/SwingCreditsDrawer/components/EmptyState.jsx';
import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { ClientDataStore } from 'dataStores';

import SwingCreditsDrawerActions from './Actions';
import SwingCreditsDrawerStore from './Store';

import './styles.scss';

function SwingCreditsDrawer({ swingCreditsDrawerStore, clientDataStore }) {
  const intl = useIntl();
  const { clients } = clientDataStore;
  const { clientId, swings, pagination, clientLoading, swingsLoading } =
    swingCreditsDrawerStore;
  const historyPagination = pagination.get('swingsHistory').toJS();
  const { page, perPage, totalCount } = historyPagination;
  const client = clients.get(clientId);
  const hasSwings = swings.length > 0;

  return (
    <Drawer
      classes={{
        root: 'swing-credits-drawer',
        footer: 'swing-credits-drawer__footer',
        header: 'swing-credits-drawer__header',
      }}
      open={Boolean(clientId)}
      title={
        <div className="swing-credits-drawer__title">
          <Typography variant="drawerTitle">
            {t('.title', intl, __filenamespace)}
          </Typography>
          {!clientLoading && <ClientDetails client={client} />}
        </div>
      }
      content={
        <SpinWhileLoading isLoading={swingsLoading} absolute={false}>
          <div className="swing-credits-drawer__history">
            {!hasSwings ? <EmptyState intl={intl} /> : null}
            {swings.map(swing => (
              <HistoryItem key={swing.id} swing={swing} />
            ))}
          </div>
        </SpinWhileLoading>
      }
      footer={
        hasSwings ? (
          <Paginator
            showInfo
            className="swing-credits-drawer__paginator"
            currentPage={page}
            perPage={perPage}
            totalCount={totalCount}
            onPageSelect={SwingCreditsDrawerActions.swingsPageSelected}
            pageLimit={0}
          />
        ) : null
      }
      onClose={() => SwingCreditsDrawerActions.mounted({ clientId: null })}
    />
  );
}

SwingCreditsDrawer.propTypes = {
  swingCreditsDrawerStore: PropTypes.object.isRequired,
  clientDataStore: PropTypes.object.isRequired,
};

export default compose(
  memo,
  altContainer({
    stores: {
      swingCreditsDrawerStore: SwingCreditsDrawerStore,
      clientDataStore: ClientDataStore,
    },
  })
)(SwingCreditsDrawer);
