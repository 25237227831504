class SwingCreditsDrawerActions {
  constructor() {
    this.generateActions(
      'mounted',
      'fetchClientSuccess',
      'fetchClientError',
      'listSwings',
      'listSwingsSuccess',
      'listSwingsError',
      'swingsPageSelected'
    );
  }
}

export default alt.createActions(SwingCreditsDrawerActions);
