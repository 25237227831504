import { DefaultSourceActions } from './DefaultSourceActionList';

class EmailCampaignsActions {
  constructor() {
    this.generateActions(
      ...DefaultSourceActions,
      'cancelSuccess',
      'cancelError',
      'listStatistics',
      'listStatisticsSuccess',
      'listStatisticsError'
    );
  }
}

export default alt.createActions(EmailCampaignsActions);
